<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile">
      <div class="tile is-parent is-half">
        <article class="tile is-child box detail-page-tile">
          <div class="columns is-gapless">
            <div class="column">
              <p class="title">Detail Info</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Item No.</label>
            <span>{{ value.itemNo }}</span>
          </div>
          <div class="field">
            <label class="label">Description</label>
            <div class="field-body">
              <div class="control desc-width">
                <input
                  name="itemDesc"
                  class="input"
                  type="text"
                  placeholder="Description"
                  v-model="value.itemDesc"
                  :class="{'is-danger' : $v.entity.itemDesc.$error}"
                  maxlength="100"
                  v-focus-inserted>
                <span
                  class="help is-danger"
                  v-if="$v.entity && !$v.entity.itemDesc.required">Description is required</span>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control is-narrow">
              <label class="label">Allow Tick</label>
              <input
                type="checkbox"
                class="is-checkradio"
                id="cbFiber"
                v-model="cbFiber"
                @click="cbFiber = !cbFiber; showHide('fiber', cbFiber)">
              <label for="cbFiber">Fiberglass</label>
              <input
                type="checkbox"
                class="is-checkradio"
                id="cbCrush"
                v-model="cbCrush"
                @click="cbCrush = !cbCrush; showHide('crush', cbCrush)">
              <label for="cbCrush">Crush</label>
              <input
                type="checkbox"
                class="is-checkradio"
                id="cbCD"
                v-model="cbCD"
                @click="cbCD = !cbCD; showHide('cd', cbCD)">
              <label for="cbCD">Clean and Detail</label>
              <input
                type="checkbox"
                class="is-checkradio"
                id="cbMech"
                v-model="cbMech"
                @click="cbMech = !cbMech; showHide('mech', cbMech)">
              <label for="cbMech">Mechanical</label>
            </div>
          </div>
          <div class="field">
            <div
              class="field-body"
              v-show="isFiber">
              <div class="field is-narrow">
                <label class="label">Fiberglass Hour</label>
                <div class="control">
                  <vue-numeric
                    class="input has-text-right"
                    v-model.number="value.fgHour"
                    :min="0.00"
                    :precision="2" />
                </div>
              </div>
            </div>
            <div
              class="field-body"
              v-show="isCrush">
              <div class="field is-narrow">
                <label class="label">Crush Hour</label>
                <div class="control">
                  <vue-numeric
                    class="input has-text-right"
                    v-model.number="value.crushHour"
                    :min="0.00"
                    :precision="2" />
                </div>
              </div>
            </div>
            <div
              class="field-body"
              v-show="isCD">
              <div class="field is-narrow">
                <label class="label">Clean and Detail Hour</label>
                <div class="control">
                  <vue-numeric
                    class="input has-text-right"
                    v-model.number="value.cdHour"
                    :min="0.00"
                    :precision="2" />
                </div>
              </div>
            </div>
            <div
              class="field-body"
              v-show="isMech">
              <div class="field is-narrow">
                <label class="label">Mechanical Hour</label>
                <div class="control">
                  <vue-numeric
                    class="input has-text-right"
                    v-model.number="value.mechHour"
                    :min="0.00"
                    :precision="2" />
                </div>
              </div>
            </div>
          </div>
          <div class="is-divider" />
          <div class="field">
            <div class="buttons has-addons is-centered">
              <span
                class="button"
                :class="{ 'is-success is-selected' : value.active }"
                @click="toggleActive(true)">
                <span class="icon is-small">
                  <i class="mdi mdi-check" />
                </span>
                <span>Active</span>
              </span>
              <span
                class="button"
                :class="{ 'is-danger is-selected' : !value.active }"
                @click="toggleActive(false)">
                <span class="icon is-small">
                  <i class="mdi mdi-close" />
                </span>
                <span>In-Active</span>
              </span>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
// import OtherLabourService from './OtherLabourService'
import VueNumeric from '@/components/VueNumeric'
import { FocusInserted } from '@/components/directives'

export default {
  name: 'OtherlabourDetail',
  inject: ['$vv'],
  components: {
    // OtherLabourService,
    VueNumeric
  },
  directives: {
    FocusInserted
  },
  props: {
    value: null,
    isNew: Boolean
  },
  data() {
    return {
      cbFiber: false,
      isFiber: false,
      cbCrush: false,
      isCrush: false,
      isCD: false,
      cbCD: false,
      isMech: false,
      cbMech: false
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.cbFiber = this.value.hasFG
    this.isFiber = this.value.hasFG
    this.cbCrush = this.value.hasCrush
    this.isCrush = this.value.hasCrush
    this.cbCD = this.value.hasCD
    this.isCD = this.value.hasCD
    this.cbMech = this.value.hasMech
    this.isMech = this.value.hasMech
  },
  mounted() {
    this.$v.detailGroup.$touch()
  },
  methods: {
    showHide(type, val) {
      switch (type) {
        case 'fiber':
          this.isFiber = val
          this.value.hasFG = val
          break
        case 'crush':
          this.isCrush = val
          this.value.hasCrush = val
          break
        case 'cd':
          this.isCD = val
          this.value.hasCD = val
          break
        case 'mech':
          this.isMech = val
          this.value.hasMech = val
          break
      }
    },
    toggleActive(active) {
      this.value.active = active
    }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
.desc-width {
  width: 60%;
}
</style>
